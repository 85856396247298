.reviews {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 60px;
  padding-bottom: 80px;
  overflow: hidden;
  position: relative;
}

.reviews__wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 25px;
}

.reviews__slider {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 40px;
}

.review {
  border-top: 2px solid $main-color;
  margin-bottom: 40px;
}

.review__title {
  margin-top: 30px;
  @include text($font-big...);
}
.reviews__description {
  @include text($font-big...);
  margin-bottom: 5px;
  margin-left: 30px;
  width: 500px;
}

.client {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
}

.client__description {
  margin-left: 10px;
}

.client__img {
  border-radius: 50%;
  width: 50px;
}
.client__stars {
  display: flex;
  align-items: center;
}
.client__level {
  @include text($font-small...);
  margin-top: 4px;
}

.cliet__date {
  @include text($font-small...);
}

.grade {
  display: flex;
  margin-top: 5px;
  align-items: center;
  align-content: center;
}

.grade__like {
  fill: $main-color;
  stroke-width: 40;
  width: 43px;
  height: 35px;
}

.grade__dislike {
  margin-top: 8px;
  margin-left: 10px;
  transform: rotate(180deg);
  fill: $second-color;
  stroke: $main-color;
  stroke-width: 40;
  width: 40px;
  height: 30px;
}

.grade__text {
  font-weight: bold;
}

// @media quories

@media (max-width: $screen-xxl) {
}

@media (max-width: $screen-xl) {
}

@media (max-width: $screen-lg) {
}

@media (max-width: $screen-md) {
  .reviews {
    max-width: 300px;
    width: 300px;
    overflow: hidden;
  }
  .reviews__wrapper {
    display: block;
    margin-bottom: 0;
  }
  .reviews__description {
    @include text($font-big-sm...);
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 0;
    width: 100%;
  }
  .review__title {
    @include text($font-big-sm...);
  }
  .cliet__date {
    @include text($font-small-sm...);
  }
  .review__text,
  .review__text {
  }
}
