@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Montserrat-Regular.ttf);
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/Montserrat-Medium.ttf);
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  src: url(../assets/fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  src: url(../assets/fonts/Inter-Regular.ttf);
}
h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 118px;
  line-height: 103px;
  text-transform: uppercase;
  width: 1400px;
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 59px;
  line-height: 57px;
  text-transform: uppercase;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
}

p {
  font-family: "Inter", sans-serif;
  font-weight: 106.5;
  font-size: 18px;
  line-height: 18px;
  text-transform: none;
}

@media (max-width: 1600px) {
  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 90px;
    line-height: 82px;
    text-transform: uppercase;
    width: 1200px;
  }
  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 59px;
    line-height: 57px;
    text-transform: uppercase;
  }
}
@media (max-width: 1280px) {
  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 90px;
    line-height: 82px;
    text-transform: uppercase;
    width: 1020px;
  }
  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 50px;
    line-height: 46px;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
  }
}
@media (max-width: 1024px) {
  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 80px;
    line-height: 75px;
    text-transform: uppercase;
    width: 700px;
  }
  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
  }
  p {
    font-family: "Inter", sans-serif;
    font-weight: 106.5;
    font-size: 16px;
    line-height: 16px;
    text-transform: none;
  }
}
@media (max-width: 758px) {
  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 34px;
    text-transform: uppercase;
    width: 300px;
  }
  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 19px;
    line-height: 19px;
    text-transform: uppercase;
  }
  p {
    font-family: "Inter", sans-serif;
    font-weight: 106.5;
    font-size: 16px;
    line-height: 16px;
    text-transform: none;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 23px;
  margin: 0;
  overflow-y: scroll;
}

.header {
  color: #ffffff;
  height: 100vh;
}

.nav {
  display: grid;
  align-items: center;
  grid-template-columns: 200px 180px auto 260px;
  margin: 0 auto;
  padding: 10px;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 10px;
}

.nav__description {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  width: 150px;
  justify-self: end;
}

.menu {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
}
.menu a:link {
  color: #ffffff;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
}
.menu a:visited {
  color: #ffffff;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
}

.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 30px;
  left: 0;
  background-color: #ffffff;
  width: 250px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}
.submenu a:link {
  color: #121420;
}
.submenu a:visited {
  color: #121420;
}

.submenu li a {
  display: block;
  padding: 15px 20px;
  text-decoration: none;
}

.submenu li a:hover {
  color: #ffffff;
  background-color: #bc1c1e;
}

.menu > li:hover .submenu {
  display: block;
  opacity: 1;
  visibility: visible;
}

.menu__item {
  position: relative;
}

.menu__item-mobile {
  display: none;
}

.adress {
  display: none;
}

.msg {
  display: none;
}

.burger {
  display: none;
}

.toogle-fix {
  overflow-y: hidden;
}

.toogle-menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-y: hidden;
  z-index: 999;
  align-content: start;
  background: #bc1c1e;
  justify-content: space-between;
  grid-template-columns: 200px 60px;
  animation: ani 0.3s forwards;
}
.toogle-menu .tel {
  position: absolute;
  bottom: 210px;
  margin-left: 10px;
}
.toogle-menu .tel__number {
  display: block;
}
.toogle-menu .adress {
  display: flex;
  position: absolute;
  bottom: 170px;
  margin-left: 10px;
}
.toogle-menu .adress a:link,
.toogle-menu .adress :hover,
.toogle-menu .adress :visited {
  color: #ffffff;
  text-decoration: none;
}
.toogle-menu .adress-icon {
  width: 20px;
  height: 27px;
  fill: white;
}
.toogle-menu .adress__text {
  padding-top: 2px;
  padding-left: 10px;
}
.toogle-menu .msg {
  display: flex;
  position: absolute;
  bottom: 80px;
  margin-left: 10px;
}
.toogle-menu .msg img {
  width: 50px;
  margin-right: 15px;
}
.toogle-menu .burger::after {
  animation: ani-burger 0.3s forwards;
}
@keyframes ani-burger {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(45deg);
    top: 38px;
  }
}
.toogle-menu .burger::before {
  animation: acni-burger 0.3s forwards;
}
@keyframes acni-burger {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(-45deg);
    top: 38px;
  }
}
.toogle-menu .nav__logo {
  padding-bottom: 0;
}
.toogle-menu .nav__description {
  display: none;
}
.toogle-menu .logo-img {
  fill: #ffffff;
}
.toogle-menu .tel-icon {
  fill: #ffffff;
}
.toogle-menu .menu {
  display: flex;
  position: absolute;
  flex-direction: column;
  animation: menu 0.6s forwards;
  top: 25%;
  margin-left: 10px;
}
.toogle-menu .menu a:link {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  text-transform: uppercase;
}
.toogle-menu .menu a:visited {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  text-transform: uppercase;
}
@keyframes menu {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
.toogle-menu .menu__item-mobile {
  display: block;
}
@keyframes ani {
  0% {
    background: none;
  }
  100% {
    background: #bc1c1e;
  }
}

.toogle-display {
  display: none;
}

.tel__number a:link {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
  text-decoration: none;
}

.header__wrapper {
  position: absolute;
  bottom: 20px;
}

.cta {
  background: #bc1c1e;
  color: #ffffff;
  border: 0;
  border-color: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  display: block;
  border: 0;
  width: 395px;
  height: 89px;
  position: absolute;
  bottom: 5px;
  left: 910px;
  text-decoration: none;
  text-align: center;
  line-height: 89px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cta-default {
  position: unset;
  margin: 0 auto;
}

.discount {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-transform: none;
  position: absolute;
  left: 200px;
  left: 1339px;
  width: 200px;
  bottom: 10px;
}

.video__container {
  background: #121420;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -9999;
}

.video__container .bgvideo {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  background-size: cover;
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: 100vh;
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1600px) {
  .header {
    margin: 0 auto;
  }
  .tel {
    justify-content: unset;
  }
  .tel__number a:link {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
  }
  .adress__text a:link {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
  }
  .nav__description {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
  }
  .cta {
    background: #bc1c1e;
    color: #ffffff;
    border: 0;
    border-color: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    text-transform: uppercase;
    border: 0;
    width: 290px;
    height: 65px;
    position: absolute;
    bottom: 8px;
    line-height: 65px;
    left: 670px;
  }
  .cta-default {
    position: unset;
    margin: 0 auto;
  }
  .discount {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-transform: none;
    position: absolute;
    left: 200px;
    left: 990px;
    width: 200px;
    bottom: 18px;
  }
  .video__container .bgvideo {
    height: 110vh;
  }
}
@media (max-width: 1280px) {
  .submenu {
    opacity: 1;
    visibility: visible;
    position: relative;
    background: none;
    top: 0;
    padding: 5px 0;
    width: 270px;
  }
  .nav {
    grid-template-columns: 200px auto 260px 50px;
  }
  .menu {
    display: none;
  }
  .toogle-menu {
    justify-content: space-between;
    grid-template-columns: 160px 60px;
    position: fixed;
    width: 100%;
    overflow-y: hidden;
  }
  .toogle-menu .menu {
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px;
    top: 19%;
  }
  .toogle-menu .menu__item {
    width: 300px;
    margin-right: 60px;
  }
  .toogle-menu .submenu__item a:link,
.toogle-menu .submenu__item a:visited {
    background: none;
    font-size: 24px;
    padding: 0;
    line-height: 24px;
    color: #ffffff;
    opacity: 0.8;
  }
  .burger {
    display: flex;
    cursor: pointer;
    width: 50px;
    height: 50px;
    justify-content: flex-end;
  }
  .burger::after {
    content: " ";
    display: block;
    background-color: #ffffff;
    width: 43px;
    height: 2px;
    position: absolute;
    top: 37px;
  }
  .burger::before {
    content: " ";
    display: block;
    background-color: #ffffff;
    width: 43px;
    height: 2px;
    position: absolute;
    top: 44px;
  }
  .nav__description {
    justify-self: normal;
  }
}
@media (max-width: 1024px) {
  .cta {
    position: unset;
    margin-top: 20px;
  }
  .discount {
    left: 300px;
    bottom: 4px;
  }
  .toogle-menu {
    grid-template-columns: 160px 60px;
  }
}
@media (max-width: 758px) {
  .header {
    height: 95vh;
    overflow: hidden;
    min-height: 460px;
    position: relative;
  }
  .nav {
    grid-template-columns: 200px auto 60px;
  }
  .toogle-menu {
    grid-template-columns: 200px 60px;
  }
  .toogle-menu .menu__item {
    margin-right: 0px;
  }
  .nav__description {
    display: block;
    position: absolute;
    bottom: 355px;
  }
  .tel {
    justify-content: flex-end;
  }
  .tel__number {
    display: none;
  }
  .cta {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    width: 100%;
    line-height: 65px;
    margin-bottom: 15px;
    height: 65px;
  }
  .cta-default {
    position: unset;
    margin: 0 auto;
  }
  .discount {
    position: unset;
    margin-left: 50%;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
    width: 140px;
  }
  .header__wrapper {
    left: 10px;
    right: 10px;
    bottom: 60px;
  }
  .burger::after {
    top: 31px;
  }
  .burger::before {
    top: 37px;
  }
}
.services {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  padding-top: 110px;
  padding-bottom: 95px;
}

.services-heading {
  width: 100px;
}

.services__wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: center;
  margin-bottom: 60px;
}

.services-list {
  align-self: center;
}

.services-list__item {
  position: relative;
  flex-wrap: wrap;
  align-content: start;
  height: 200px;
  list-style: none;
  width: 335px;
  display: flex;
  align-items: flex-end;
  border-top: 2px solid;
  padding-top: 25px;
}
.services-list__item::before {
  content: " ";
  position: absolute;
  background: url(../assets/img/arrow-circle.svg) no-repeat;
  width: 70px;
  height: 55px;
  top: 20px;
}
.services-list__item--heading {
  margin-left: 40px;
}
.services-list__item--text {
  margin-left: 40px;
  margin-top: 15px;
}

@media (max-width: 1280px) {
  .services-list__item {
    height: 185px;
  }
  .services-list__img {
    width: 300px;
  }
}
@media (max-width: 1024px) {
  .services {
    width: 640px;
  }
  .services__wrapper {
    grid-template-columns: 1fr;
    justify-items: start;
  }
  .services-list__img {
    position: absolute;
    left: 360px;
    top: 30%;
    width: 270px;
  }
  .services-list:nth-of-type(1) .services-list__item:first-child {
    margin-top: 20px;
    border-top: none;
  }
}
@media (max-width: 758px) {
  .services {
    padding-top: 60px;
    max-width: 300px;
    padding-bottom: 60px;
  }
  .services__wrapper {
    justify-items: center;
  }
  .services-list__img {
    position: unset;
    width: 170px;
    margin-top: 60px;
  }
  .services-list__item {
    width: 300px;
    height: 180px;
  }
  .services-list:nth-of-type(2) .services-list__item:first-child {
    margin-top: 60px;
    border-top: none;
  }
}
.description {
  background: #121420;
  color: #ffffff;
}

.description__wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: justify;
  padding-bottom: 60px;
}

.description__text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
}

@media (max-width: 1024px) {
  .description__wrapper {
    max-width: 640px;
  }
  .description__text {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
  }
}
@media (max-width: 758px) {
  .description__wrapper {
    max-width: 280px;
  }
  .description__text {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
  }
}
.price {
  background: #121420;
  color: #ffffff;
  padding-bottom: 60px;
}

.price__wrapper {
  margin: 0 auto;
  padding-top: 75px;
  max-width: 1200px;
  display: grid;
  grid-template-areas: "a b" "c b" "d b" ". b";
  grid-column-gap: 20px;
}

.price-tabs {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  grid-area: c;
  margin-top: 20px;
  margin-bottom: 30px;
}

.active-tab {
  background: #ffffff;
  color: #121420;
}

.price-tabs__item {
  margin-right: 15px;
  padding: 12px 8px;
  border: 1px solid #ffffff;
  cursor: pointer;
  margin: 5px 5px 0px 0px;
}

.price__about {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  margin-top: 35px;
}

.price-heading {
  grid-area: a;
  height: 70px;
}

.price-button {
  background: #ffffff;
  color: #121420;
  border: 0;
  border-color: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  grid-area: d;
  height: 89px;
  text-align: center;
  line-height: 89px;
  text-decoration: none;
  border: 0;
}
.price-button:visited {
  color: #121420;
}

.price__text {
  grid-area: b;
  display: none;
}

.active-tab-c {
  display: block;
}

.price-text__wrapper {
  border: 1px solid;
  border-color: #ffffff;
  padding: 38px;
  margin-bottom: 30px;
}

.price-list {
  border-collapse: collapse;
  border-top: 0px;
  padding: 20px;
}

.price-list__header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 20px;
  width: 480px;
}

th,
td {
  text-align: left;
  padding: 20px 14px 20px 0px;
  width: 160px;
}

.price-list__row {
  border-bottom: 1px solid;
}

@media (max-width: 1600px) {
  .price-button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
}
@media (max-width: 1280px) {
  .price-button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
  .price__wrapper {
    max-width: 964px;
  }
}
@media (max-width: 1024px) {
  .price-button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
  .price-heading {
    height: 40px;
  }
  .price-list__header {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
  }
  .price__wrapper {
    grid-template-areas: "a" "c" "b" "d";
    max-width: 640px;
  }
}
@media (max-width: 758px) {
  .price-list__header {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 19px;
    line-height: 19px;
    text-transform: uppercase;
  }
  th {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-transform: none;
  }
  td {
    font-family: "Inter", sans-serif;
    font-weight: 106.5;
    font-size: 16px;
    line-height: 16px;
    text-transform: none;
  }
  .price__wrapper {
    max-width: 300px;
  }
  .price__text {
    width: 300px;
  }
  .price-text__wrapper {
    overflow: auto;
    padding: 25px;
    padding-top: 35px;
  }
  .price-list__header {
    padding-right: 210px;
  }
  .price-list__row:first-child .price-list__cell {
    padding-top: 0;
  }
  .price-list {
    width: 550px;
  }
  .price-button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
}
.about {
  background: #121420;
  color: #ffffff;
  padding-top: 60px;
  padding-bottom: 60px;
}

.about__wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 60px;
}

.about__heading {
  color: #ffffff;
  width: 100%;
}

.about__heading {
  margin: 0 auto;
  max-width: 1200px;
}

.about__wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  padding-bottom: 90px;
}

.about__swiper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  margin-top: 50px;
}

.about__benefit {
  grid-column-start: 2;
}

.benefit-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  gap: 150px;
  align-content: space-between;
}

.benefit-list__item {
  width: 230px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
}

.about__dtext {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 59px;
  line-height: 57px;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .about__wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .about__img1 {
    width: 100%;
  }
  .about__benefit {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
@media (max-width: 758px) {
  .about__heading {
    max-width: 300px;
    margin-bottom: 30px;
  }
  .about__wrapper {
    grid-template-columns: 1fr;
    max-width: 300px;
    padding-top: 0;
    padding-bottom: 60px;
  }
  .about__swiper {
    margin-top: 40px;
    max-width: 300px;
  }
  .about__text {
    font-family: "Inter", sans-serif;
    font-weight: 106.5;
    font-size: 16px;
    line-height: 16px;
    text-transform: none;
    width: 100%;
  }
  .about__img1,
.about__img2,
.about__img3 {
    width: 100%;
    margin-bottom: 30px;
  }
  .about__benefit {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .about__dtext {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 34px;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 25px;
  }
  .benefit-list {
    gap: 90px;
  }
  .benefit-list__item {
    border-top: 2px solid #ffffff;
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 19px;
    line-height: 19px;
    text-transform: uppercase;
    width: 190px;
  }
}
.swiper {
  width: 1200px;
  height: 600px;
  position: unset;
}

.swiper2 {
  margin-top: 40px;
  width: 1200px;
  height: 710px;
  position: unset;
  overflow: hidden;
}

.swiper__slide {
  width: 100%;
}

.swiper-button-prev {
  position: absolute;
  top: 36px;
  right: 140px;
  left: unset;
}
.swiper-button-prev::after {
  content: unset;
}

.swiper-button-next {
  position: absolute;
  top: 36px;
  right: 60px;
  left: unset;
}
.swiper-button-next::after {
  content: unset;
}

.swiper-button-prev2 {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 140px;
  left: unset;
}
.swiper-button-prev2::after {
  content: unset;
}

.swiper-button-next2 {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 60px;
  left: unset;
}
.swiper-button-next2::after {
  content: unset;
}

.swiper-button-prev3 {
  position: absolute;
  cursor: pointer;
  top: 50px;
  right: 140px;
  left: unset;
}
.swiper-button-prev3::after {
  content: unset;
}

.swiper-button-next3 {
  position: absolute;
  cursor: pointer;
  top: 50px;
  right: 60px;
  left: unset;
}
.swiper-button-next3::after {
  content: unset;
}

.slider {
  position: relative;
  max-width: 1200px;
  margin: 75px auto;
}

.slider__heading {
  margin-bottom: 30px;
}

.slider-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
.slider-button--right {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
  margin-top: 4px;
}

@media (max-width: 1280px) {
  .slider {
    max-width: 964px;
  }
  .swiper {
    width: 100%;
    height: 540px;
  }
  .swiper2 {
    width: 100%;
    height: 540px;
  }
}
@media (max-width: 1024px) {
  .slider {
    max-width: 640px;
  }
  .swiper {
    width: 100%;
    height: 350px;
  }
  .slider2 {
    display: flex;
    flex-wrap: wrap;
  }
  .swiper2 {
    width: 100%;
    height: 350px;
    margin-top: 30px;
    order: 3;
  }
}
@media (max-width: 758px) {
  .about {
    padding-bottom: 120px;
  }
  .slider {
    max-width: 320px;
  }
  .swiper {
    width: 100%;
    height: 185px;
  }
  .swiper2 {
    width: 100%;
    height: 175px;
  }
  .swiper-button-prev3 {
    top: 670px;
    right: 70px;
  }
  .swiper-button-prev3 img {
    width: 50px;
  }
  .swiper-button-next3 {
    top: 670px;
    right: 10px;
  }
  .swiper-button-next3 img {
    width: 50px;
  }
  .swiper-button-prev2 {
    top: 530px;
    right: 70px;
  }
  .swiper-button-prev2 img {
    width: 50px;
  }
  .swiper-button-next2 {
    top: 530px;
    right: 10px;
  }
  .swiper-button-next2 img {
    width: 50px;
  }
  .swiper-button-prev {
    top: 280px;
    right: 70px;
  }
  .swiper-button-prev img {
    width: 50px;
  }
  .swiper-button-next {
    top: 280px;
    right: 10px;
  }
  .swiper-button-next img {
    width: 50px;
  }
}
.contacts {
  background: #121420;
  color: #ffffff;
}

.contacts__map-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  grid-column: 1/3;
}

#map {
  width: 100% px;
  height: 500px;
}

.ymaps-2-1-79-map {
  filter: grayscale(1);
  -ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  mix-blend-mode: difference;
  width: 100%;
  height: 100%;
}

.shape-map {
  mix-blend-mode: difference;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ffffff;
  top: 0;
}

.contacts__wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.contacts__heading {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 59px;
  line-height: 57px;
  text-transform: uppercase;
  color: #ffffff;
  grid-column: 1/4;
}

.contacts__map {
  grid-column: 1/3;
}

.contacts__tel {
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 5px;
}
.contacts__tel-icon .tel-icon {
  padding-top: 0;
}

.contacts__address {
  display: flex;
}
.contacts__address-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
}
.contacts__address-text a {
  text-decoration: none;
  color: #ffffff;
}
.contacts__address-icon {
  width: 45px;
  fill: #bc1c1e;
  margin-right: 20px;
}

.contacts__time {
  width: 212px;
  padding-bottom: 20px;
}

.contacts__text {
  padding-bottom: 20px;
}

.contacts__cta-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
}

.contacts__msg {
  margin-top: 15px;
  margin-bottom: 15px;
}
.contacts__msg a:link {
  color: transparent;
}
.contacts__msg img {
  width: 40px;
  margin-right: 20px;
}

.contacts__form {
  grid-column: 1/4;
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
  margin-top: 30px;
}

.contacts__input {
  height: 89px;
  width: 350px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  border: 2px solid;
  padding: 10px;
  border-color: #ffffff;
  background: #121420;
  color: #ffffff;
}

.contacts__button {
  background: #bc1c1e;
  color: #ffffff;
  border: 0;
  border-color: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  width: 360px;
  height: 89px;
}

@media (max-width: 1600px) {
  .contacts__input {
    height: 65px;
    line-height: 65px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    text-transform: uppercase;
  }
  .contacts__button {
    line-height: 65px;
    background: #bc1c1e;
    color: #ffffff;
    border: 0;
    border-color: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    text-transform: uppercase;
    height: 65px;
  }
}
@media (max-width: 758px) {
  .contacts__wrapper {
    grid-template-columns: 1fr;
    flex-wrap: wrap;
    max-width: 300px;
  }
  .contacts__heading {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
    grid-column: 1/2;
  }
  .contacts__map {
    grid-column: 1/2;
    width: 100%;
  }
  .contacts__map-container {
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
  }
  #map {
    width: 300px;
    height: 300px;
  }
  .contacts__form {
    grid-column: 1/2;
    flex-wrap: wrap;
    margin-top: 0;
  }
  .contacts__input {
    width: 100%;
    height: 65px;
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
  .contacts__button {
    width: 100%;
    background: #bc1c1e;
    color: #ffffff;
    border: 0;
    border-color: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    height: 65px;
  }
  .contacts__tel,
.contacts__address,
.contacts__cta-text,
.contacts__address-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 19px;
    line-height: 19px;
    text-transform: uppercase;
    line-height: 20px;
  }
  .contacts__address-icon {
    width: 30px;
  }
  .contacts__address-text a:link {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 19px;
    line-height: 19px;
    text-transform: uppercase;
    line-height: 20px;
    color: #ffffff;
  }
  .contacts__time {
    font-family: "Inter", sans-serif;
    font-weight: 106.5;
    font-size: 16px;
    line-height: 16px;
    text-transform: none;
  }
  .contacts__text {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
  }
}
.cta-form {
  background: #121420;
}

.cta-form__wrapper {
  width: 1250px;
  background: #ffffff;
  margin: 0 auto;
}

.cta-form__content {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.cta-form__heading {
  width: 65%;
}

.cta-form__text {
  width: 32%;
}

.cta-form__input {
  width: 350px;
  height: 90px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  border: 2px solid;
  padding: 10px;
  border-color: #121420;
}

.cta-form__button {
  background: #bc1c1e;
  color: #ffffff;
  border: 0;
  border-color: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  width: 360px;
  height: 90px;
  border: 0;
}

@media (max-width: 1600px) {
  .cta-form__input {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
  .cta-form__button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
}
@media (max-width: 1280px) {
  .cta-form__input {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
  .cta-form__button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
}
@media (max-width: 1024px) {
  .cta-form__input {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
  .cta-form__button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
}
@media (max-width: 758px) {
  .cta-form {
    max-width: 320px;
    background-color: #ffffff;
    margin: 0 auto;
    padding-bottom: 50px;
  }
  .cta-form__wrapper {
    width: 300px;
  }
  .cta-form__content {
    gap: 0;
    padding: 0;
  }
  .cta-form__input {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 15px;
    height: 65px;
  }
  .cta-form__heading {
    margin-top: 30px;
  }
  .cta-form__text {
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-transform: none;
    width: 230px;
  }
  .cta-form__button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    height: 65px;
  }
}
.file-form {
  background: #121420;
  padding: 50px;
}

.file-form__wrapper {
  max-width: 1250px;
  margin: 0 auto;
  border: 2px solid #ffffff;
  padding: 50px;
}

.file-form__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 40px;
}

.file-form__label-container {
  position: relative;
}

.img__preview {
  height: 40px;
  position: absolute;
  top: 23px;
  right: 57px;
  border-radius: 5px;
  pointer-events: none;
}

.file-form__heading {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 59px;
  line-height: 57px;
  text-transform: uppercase;
  color: #ffffff;
  grid-column-start: 1;
  grid-column-end: 2;
}

.file-form__description {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-transform: none;
  color: #ffffff;
  margin-left: 50px;
  align-self: end;
  grid-column-start: 2;
  grid-column-end: 4;
}

.file-form__input {
  height: 90px;
  width: 350px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  border: 2px solid;
  padding: 10px;
  border-color: #121420;
}

.file-form__label-file {
  height: 90px;
  width: 350px;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  border: 2px solid;
  padding: 10px;
  border-color: #121420;
  background: #ffffff;
  line-height: 65px;
  margin-bottom: 15px;
}

.file-form__input-file {
  height: 89px;
  width: 200px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  border: 2px solid;
  padding: 10px;
  border-color: #121420;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.file-form__button {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  background: #bc1c1e;
  color: #ffffff;
  border: 0;
  border-color: none;
  width: 360px;
  height: 89px;
}

@media (max-width: 1600px) {
  .file-form__input {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
  .file-form__input-file {
    line-height: 65px;
    height: 65px;
  }
  .file-form__label-file {
    height: 65px;
    line-height: 65px;
    cursor: pointer;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    text-transform: uppercase;
    padding: 10px;
    border-color: #121420;
    background: #ffffff;
  }
  .file-form__button {
    line-height: 65px;
    height: 65px;
    background: #bc1c1e;
    color: #ffffff;
    border: 0;
    border-color: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    text-transform: uppercase;
  }
}
@media (max-width: 1024px) {
  .file-form__input {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    line-height: 65px;
    height: 65px;
  }
  .file-form__input-file {
    line-height: 65px;
    height: 65px;
  }
  .file-form__button {
    line-height: 65px;
    height: 65px;
    background: #bc1c1e;
    color: #ffffff;
    border: 0;
    border-color: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
}
@media (max-width: 758px) {
  .img__preview {
    top: 13px;
    right: 30px;
  }
  .file-form {
    padding: 50px 0;
  }
  .file-form__wrapper {
    border-right: 1;
    border-left: 1;
    margin: 0 15px;
    max-width: none;
    padding: 50px 0;
  }
  .file-form__heading {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
    width: 200px;
    grid-column-end: none;
  }
  .file-form__content {
    grid-template-columns: none;
    max-width: 300px;
    margin: 0 auto;
    grid-row-gap: 0;
  }
  .file-form__description {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-transform: none;
    grid-column-start: 1;
    grid-column-end: none;
    width: 100%;
    margin: 15px 0;
  }
  .file-form__input {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    width: 100%;
    height: 65px;
    margin-bottom: 15px;
  }
  .file-form__input-file {
    width: 200px;
    height: 65px;
  }
  .file-form__button {
    width: 100%;
    height: 65px;
    background: #bc1c1e;
    color: #ffffff;
    border: 0;
    border-color: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
}
.footer {
  background: #121420;
}

.footer__wrapper {
  color: #ffffff;
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.footer__logo {
  grid-column: 1/2;
}

.footer__logo-img {
  width: 162px;
}

.footer__logo-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 19px;
  line-height: 16px;
  text-transform: uppercase;
}

.footer__logo a:link,
:visited {
  text-decoration: none;
  color: #121420;
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-menu {
  margin-top: 20px;
  grid-column: 2/3;
}

.footer-menu__item {
  width: 40%;
  list-style: none;
}

.footer-menu__item a {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
}

.footer__vk {
  display: block;
  grid-column: 3/4;
  text-align: right;
  margin-top: 20px;
}

.footer__company {
  margin-top: 120px;
  padding-bottom: 50px;
  color: #ffffff;
}

.footer__copy {
  grid-column: 3/4;
  text-align: right;
  padding-bottom: 50px;
  align-self: flex-end;
}

@media (max-width: 758px) {
  .footer__wrapper {
    grid-template-columns: 1fr;
    max-width: 300px;
  }
  .footer-menu {
    grid-column: 1/4;
  }
  .footer-menu__item {
    width: 145px;
  }
  .footer__logo {
    padding-bottom: 10px;
    text-align: left;
  }
  .footer__logo-img {
    width: 100px;
  }
  .footer__logo-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 8px;
    text-transform: uppercase;
  }
  .footer__vk {
    grid-column: 1/4;
    text-align: left;
  }
  .footer__copy {
    grid-column: 1/4;
    text-align: left;
  }
}
.reviews {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 60px;
  padding-bottom: 80px;
  overflow: hidden;
  position: relative;
}

.reviews__wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 25px;
}

.reviews__slider {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 40px;
}

.review {
  border-top: 2px solid #121420;
  margin-bottom: 40px;
}

.review__title {
  margin-top: 30px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-transform: none;
}

.reviews__description {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-transform: none;
  margin-bottom: 5px;
  margin-left: 30px;
  width: 500px;
}

.client {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
}

.client__description {
  margin-left: 10px;
}

.client__img {
  border-radius: 50%;
  width: 50px;
}

.client__stars {
  display: flex;
  align-items: center;
}

.client__level {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  margin-top: 4px;
}

.cliet__date {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
}

.grade {
  display: flex;
  margin-top: 5px;
  align-items: center;
  align-content: center;
}

.grade__like {
  fill: #121420;
  stroke-width: 40;
  width: 43px;
  height: 35px;
}

.grade__dislike {
  margin-top: 8px;
  margin-left: 10px;
  transform: rotate(180deg);
  fill: #ffffff;
  stroke: #121420;
  stroke-width: 40;
  width: 40px;
  height: 30px;
}

.grade__text {
  font-weight: bold;
}

@media (max-width: 758px) {
  .reviews {
    max-width: 300px;
    width: 300px;
    overflow: hidden;
  }
  .reviews__wrapper {
    display: block;
    margin-bottom: 0;
  }
  .reviews__description {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-transform: none;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 0;
    width: 100%;
  }
  .review__title {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-transform: none;
  }
  .cliet__date {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
  }
}
.thx__container {
  background: #121420;
  width: 100%;
  height: 100vh;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.thx__text {
  width: 300px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -125px 0 0 -125px;
}

.thx__contacts {
  margin-top: 30px;
}

.thx__heading {
  width: 100%;
  font-size: 25px;
}

.thx__tel {
  font-size: 30px;
  margin-bottom: 60px;
  margin-top: 20px;
}
.thx__tel a:link {
  color: #fff;
  text-decoration: none;
}

.nav__logo {
  text-align: center;
  padding-bottom: 20px;
}

.nav__logo a:link, :visited {
  text-decoration: none;
  color: #ffffff;
}

.nav__logo-img {
  width: 162px;
}

.logo-img {
  fill: #bc1c1e;
}

.nav__logo-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 19px;
  line-height: 16px;
  text-transform: uppercase;
}

@media (max-width: 1600px) {
  .nav__logo-img {
    width: 130px;
  }
  .nav__logo-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
  }
}
@media (max-width: 758px) {
  .nav__logo {
    padding-bottom: 10px;
    text-align: left;
  }
  .nav__logo-img {
    width: 100px;
  }
  .nav__logo-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 8px;
    text-transform: uppercase;
  }
}
.tel {
  display: flex;
  align-items: center;
}

.tel-icon {
  width: 30px;
  padding-top: 6px;
  margin-right: 11px;
  fill: #bc1c1e;
}

@media (max-width: 1600px) {
  .tel-icon {
    width: 25px;
  }
}
@media (max-width: 758px) {
  .tel-icon {
    width: 20px;
  }
}