.file-form {
  background: $main-color;
  padding: 50px;
}

.file-form__wrapper {
  max-width: 1250px;
  margin: 0 auto;
  border: 2px solid $second-color;
  padding: 50px;
}

.file-form__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 40px;
}

.file-form__label-container {
	position: relative;
}
.img__preview {
	height: 40px;
  position: absolute;
  top: 23px;
  right: 57px;
  border-radius: 5px;
	pointer-events: none;
}

.file-form__heading {
  @include text($font-h2...);
  color: $second-color;
  grid-column-start: 1;
  grid-column-end: 2;
}

.file-form__description {
  @include text($font-big...);
  color: $second-color;
  margin-left: 50px;
  align-self: end;
  grid-column-start: 2;
  grid-column-end: 4;
}

.file-form__input {
  height: 90px;
  width: 350px;
  @include text($font-button...);
  border: 2px solid;
  padding: 10px;
  border-color: $main-color;
}
.file-form__label-file {
  height: 90px;
  width: 350px;
  display: flex;
  @include text($font-button...);
  border: 2px solid;
  padding: 10px;
  border-color: $main-color;
  background: $second-color;
  line-height: 65px;
  margin-bottom: 15px;
}
.file-form__input-file {
  height: 89px;
  width: 200px;
  @include text($font-button...);
  border: 2px solid;
  padding: 10px;
  border-color: $main-color;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.file-form__button {
  @include text($font-button...);
  @include button($cta-button...);
  width: 360px;
  height: 89px;
}

// @media quories

@media (max-width: $screen-xxl) {
  .file-form__input {
    @include text($font-button-xl...);
    line-height: 65px;
    height: 65px;
  }
  .file-form__input-file {
    line-height: 65px;
    height: 65px;
  }
  .file-form__label-file {
    height: 65px;
    line-height: 65px;
    cursor: pointer;
    width: 100%;
    @include text($font-button-xl...);
    padding: 10px;
    border-color: $main-color;
    background: $second-color;
  }

  .file-form__button {
    line-height: 65px;
    height: 65px;
    @include button($cta-button...);
    @include text($font-button-xl...);
  }
}

@media (max-width: $screen-xl) {
}

@media (max-width: $screen-lg) {
  .file-form__input {
    @include text($font-button-md...);
    line-height: 65px;
    height: 65px;
  }
  .file-form__input-file {
    line-height: 65px;
    height: 65px;
  }

  .file-form__button {
    line-height: 65px;
    height: 65px;
    @include button($cta-button...);
    @include text($font-button-md...);
  }
}

@media (max-width: $screen-md) {
.img__preview {
  top: 13px;
  right: 30px;
}
  .file-form {
    padding: 50px 0;
  }
  .file-form__wrapper {
    border-right: 1;
    border-left: 1;
    margin: 0 15px;
    max-width: none;
    padding: 50px 0;
  }
  .file-form__heading {
    @include text($font-h2-sm...);
    width: 200px;
    grid-column-end: none;
  }
  .file-form__content {
    grid-template-columns: none;
    max-width: 300px;
    margin: 0 auto;
    grid-row-gap: 0;
  }
  .file-form__description {
    @include text($font-big-sm...);
    grid-column-start: 1;
    grid-column-end: none;
    width: 100%;
    margin: 15px 0;
  }
  .file-form__input {
    @include text($font-button-sm...);
    width: 100%;
    height: 65px;
    margin-bottom: 15px;
  }
  .file-form__input-file {
    width: 200px;
    height: 65px;
  }

  .file-form__button {
    width: 100%;
    height: 65px;
    @include button($cta-button...);
    @include text($font-button-sm...);
  }
}
