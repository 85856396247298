.footer {
  background: $main-color;
}

.footer__wrapper {
  color: $second-color;
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.footer__logo {
  grid-column: 1/2;
}
.footer__logo-img {
  width: 162px;
}
.footer__logo-text {
  @include text($font-logo...);
}

.footer__logo a:link,
:visited {
  text-decoration: none;
  color: $main-color;
}
.footer-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-menu {
  margin-top: 20px;
  grid-column: 2/3;
}
.footer-menu__item {
  width: 40%;
  list-style: none;
}
.footer-menu__item a {
  @include text($font-nav...);
  color: $second-color;
  text-decoration: none;
}

.footer__vk {
  display: block;
  grid-column: 3/4;
  text-align: right;
  margin-top: 20px;
}

.footer__company {
  margin-top: 120px;
  padding-bottom: 50px;
  color: $second-color;
}
.footer__copy {
  grid-column: 3/4;
  text-align: right;
  padding-bottom: 50px;
  align-self: flex-end;
}

@media (max-width: $screen-md) {
  .footer__wrapper {
    grid-template-columns: 1fr;
    max-width: 300px;
  }
  .footer-menu {
    grid-column: 1/4;
  }
  .footer-menu__item {
    width: 145px;
  }
  .footer__logo {
    padding-bottom: 10px;
    text-align: left;
  }
  .footer__logo-img {
    width: 100px;
  }
  .footer__logo-text {
    @include text($font-logo-sm...);
  }
  .footer__vk {
    grid-column: 1/4;
    text-align: left;
  }
  .footer__copy {
    grid-column: 1/4;
    text-align: left;
  }
}
