// Fonts variables
$font-type-standart: 'Inter', sans-serif;
$font-type-second: 'Montserrat', sans-serif;

// 1600px and max xxl
$font-display: $font-type-second, 118px, 700, 103px, uppercase;
$font-h2: $font-type-second, 59px, 700, 57px, uppercase;
$font-h3: $font-type-second, 24px, 700, 25px, uppercase;
$font-big: $font-type-standart, 22px, 600, 26px, none;
$font-text: $font-type-standart, 18px, 106.5, 18px, none;
$font-small: $font-type-standart, 14px, 400, 14px, none;
$font-button: $font-type-second, 24px, 600, 24px, uppercase;
$font-nav: $font-type-second, 16px, 700, 16px, uppercase;
$font-logo: $font-type-second, 19px, 700, 16px, uppercase;

// 1280px-1600px xl
$font-display-xl: $font-type-second, 90px, 700, 82px, uppercase;
$font-h2-xl: $font-type-second, 59px, 700, 57px, uppercase;
$font-h3-xl: $font-type-second, 24px, 700, 25px, uppercase;
$font-big-xl: $font-type-standart, 18px,600, 20px, none;
$font-text-xl: $font-type-standart, 18px, 106.5, 103px, none;
$font-small-xl: $font-type-standart, 14px, 400, 14px, none;
$font-button-xl: $font-type-second, 18px, 600, 44px, uppercase;
$font-nav-xl: $font-type-second, 16px, 700, 16px, uppercase;
$font-logo-xl: $font-type-second, 16px, 700, 16px, uppercase;

// 1024px-1280px lg
$font-display-lg: $font-type-second, 90px, 700, 82px, uppercase;
$font-h2-lg: $font-type-second, 50px, 700, 46px, uppercase;
$font-h3-lg: $font-type-second, 22px, 700, 22px, uppercase;
$font-big-lg: $font-type-standart, 22px, 600, 26px, none;
$font-text-lg: $font-type-standart, 18px, 106.5, 103px, none;
$font-small-lg: $font-type-standart, 14px, 400, 14px, none;
$font-button-lg: $font-type-second, 18px, 600, 24px, uppercase;
$font-nav-lg: $font-type-second, 16px, 700, 16px, uppercase;
$font-logo-lg: $font-type-second, 19px, 700, 16px, uppercase;

// 758px-1024px md
$font-display-md: $font-type-second, 80px, 700, 75px, uppercase;
$font-h2-md: $font-type-second, 48px, 700, 48px, uppercase;
$font-h3-md: $font-type-second, 22px, 700, 22px, uppercase;
$font-big-md: $font-type-standart, 22px, 600, 26px, none;
$font-text-md: $font-type-standart, 16px, 106.5, 16px, none;
$font-small-md: $font-type-standart, 14px, 400, 14px, none;
$font-button-md: $font-type-second, 18px, 600, 24px, uppercase;
$font-nav-md: $font-type-second, 16px, 700, 16px, uppercase;
$font-logo-md: $font-type-second, 19px, 700, 16px, uppercase;

// 320px-758px sm
$font-display-sm: $font-type-second, 36px, 700, 34px, uppercase;
$font-h2-sm: $font-type-second, 32px, 700, 32px, uppercase;
$font-h3-sm: $font-type-second, 19px, 700, 19px, uppercase;
$font-big-sm: $font-type-standart, 18px, 600, 18px, none;
$font-text-sm: $font-type-standart, 16px, 106.5, 16px, none;
$font-small-sm: $font-type-standart, 14px, 400, 14px, none;
$font-button-sm: $font-type-second, 18px, 600, 24px, uppercase;
$font-nav-sm: $font-type-second, 32px, 700, 35px, uppercase;
$font-logo-sm: $font-type-second, 12px, 700, 8px, uppercase;

@mixin text($font-type, $size, $weight, $height, $transform) {
  font: {
    family: $font-type;
    weight: $weight;
    size: $size;
  }
  line-height: $height;
  text-transform: $transform;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: url(../assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  src: url(../assets/fonts/Inter-Regular.ttf);
}

h1 {
  @include text($font-display...);
  width: 1400px;
}

h2 {
  @include text($font-h2...);
}

h3 {
  @include text($font-h3...);
}

p {
  @include text($font-text...);
}

@media (max-width: $screen-xxl) {
  h1 {
    @include text($font-display-xl...);
    width: 1200px;
  }

  h2 {
    @include text($font-h2-xl...);
  }
}

@media (max-width: $screen-xl) {
  h1 {
    @include text($font-display-lg...);
    width: 1020px;
  }

  h2 {
    @include text($font-h2-lg...);
  }

  h3 {
    @include text($font-h3-lg...);
  }
}

@media (max-width: $screen-lg) {
  h1 {
    @include text($font-display-md...);
    width: 700px;
  }

  h2 {
    @include text($font-h2-md...);
  }

  h3 {
    @include text($font-h3-md...);
  }

	p {
		@include text($font-text-md...);
	}
}

@media (max-width: $screen-md) {
  h1 {
    @include text($font-display-sm...);
    width: 300px;
  }
  h2 {
    @include text($font-h2-sm...);
  }

  h3 {
    @include text($font-h3-sm...);
  }

	p {
		@include text($font-text-sm...);
	}
}
