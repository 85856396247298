.about {
  background: $main-color;
  color: $second-color;
  padding-top: 60px;
	padding-bottom: 60px;
}

.about__wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 60px;
}

.about__heading {
  color: $second-color;
	width: 100%;
}

.about__heading {
  margin: 0 auto;
  max-width: 1200px;
}

.about__wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  padding-bottom: 90px;
}

.about__swiper{
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
	margin-top: 50px;
}

.about__benefit {
  grid-column-start: 2;
}

.benefit-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  gap: 150px;
  align-content: space-between;
}

.benefit-list__item {
  width: 230px;
  @include text($font-h3...);
}
.about__dtext {
  @include text($font-h2...);
}

// @media quories

@media (max-width: $screen-xxl) {
}

@media (max-width: $screen-xl) {
}

@media (max-width: $screen-lg) {
  .about__wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .about__img1 {
    width: 100%;
  }
  .about__benefit {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media (max-width: $screen-md) {
  .about__heading {
    max-width: 300px;
    margin-bottom: 30px;
  }

  .about__wrapper {
    grid-template-columns: 1fr;
    max-width: 300px;
    padding-top: 0;
    padding-bottom: 60px;
  }

	.about__swiper{
		margin-top: 40px;
		max-width: 300px;
	}

  .about__text {
    @include text($font-text-sm...);
    width: 100%;
  }

  .about__img1,
  .about__img2,
  .about__img3 {
    width: 100%;
    margin-bottom: 30px;
  }

  .about__benefit {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .about__dtext {
    @include text($font-display-sm...);
    width: 100%;
    margin-bottom: 25px;
  }
  .benefit-list {
    gap: 90px;
  }

  .benefit-list__item {
    border-top: 2px solid $second-color;
    padding-top: 20px;
    @include text($font-h3-sm...);
    width: 190px;
  }
}
