.swiper {
  width: 1200px;
  height: 600px;
  position: unset;
}
.swiper2 {
  margin-top: 40px;
  width: 1200px;
  height: 710px;
  position: unset;
  overflow: hidden;
}

.swiper__slide {
  width: 100%;
}

.swiper-button-prev {
  position: absolute;
  top: 36px;
  right: 140px;
  left: unset;
  &::after {
    content: unset;
  }
}
.swiper-button-next {
  position: absolute;
  top: 36px;
  right: 60px;
  left: unset;
  &::after {
    content: unset;
  }
}
.swiper-button-prev2 {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 140px;
  left: unset;
  &::after {
    content: unset;
  }
}
.swiper-button-next2 {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 60px;
  left: unset;
  &::after {
    content: unset;
  }
}
.swiper-button-prev3 {
  position: absolute;
  cursor: pointer;
  top: 50px;
  right: 140px;
  left: unset;
  &::after {
    content: unset;
  }
}
.swiper-button-next3 {
  position: absolute;
  cursor: pointer;
  top: 50px;
  right: 60px;
  left: unset;
  &::after {
    content: unset;
  }
}

.slider {
  position: relative;
  max-width: 1200px;
  margin: 75px auto;
}

.slider__heading {
  margin-bottom: 30px;
}
.slider-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  &--right {
    -moz-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
    margin-top: 4px;
  }
}

@media (max-width: $screen-xl) {
  .slider {
    max-width: 964px;
  }
  .swiper {
    width: 100%;
    height: 540px;
  }

  .swiper2 {
    width: 100%;
    height: 540px;
  }
}

@media (max-width: $screen-lg) {
  .slider {
    max-width: 640px;
  }
  .swiper {
    width: 100%;
    height: 350px;
  }
  .slider2 {
    display: flex;
    flex-wrap: wrap;
  }
  .swiper2 {
    width: 100%;
    height: 350px;
    margin-top: 30px;
    order: 3;
  }
}

@media (max-width: $screen-md) {
  .about {
    padding-bottom: 120px;
  }
  .slider {
    max-width: 320px;
  }
  .swiper {
    width: 100%;
    height: 185px;
  }
  .swiper2 {
    width: 100%;
    height: 175px;
  }
  .swiper-button-prev3 {
    top: 670px;
    right: 70px;
    img {
      width: 50px;
    }
  }
  .swiper-button-next3 {
    top: 670px;
    right: 10px;
    img {
      width: 50px;
    }
  }
  .swiper-button-prev2 {
    top: 530px;
    right: 70px;
    img {
      width: 50px;
    }
  }
  .swiper-button-next2 {
    top: 530px;
    right: 10px;
    img {
      width: 50px;
    }
  }
  .swiper-button-prev {
    top: 280px;
    right: 70px;
    img {
      width: 50px;
    }
  }
  .swiper-button-next {
    top: 280px;
    right: 10px;
    img {
      width: 50px;
    }
  }
}
