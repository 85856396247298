.header {
  color: $second-color;
  height: 100vh;
}

.nav {
  display: grid;
  align-items: center;
  grid-template-columns: 200px 180px auto 260px;
  margin: 0 auto;
  padding: 10px;
  border-bottom: 1px solid $second-color;
  padding-bottom: 10px;
}

.nav__description {
  @include text($font-small...);
  width: 150px;
  justify-self: end;
}

.menu {
  display: flex;
  justify-content: space-evenly;
  list-style: none;

  a:link {
    color: $second-color;
    text-decoration: none;
    @include text($font-nav...);
  }

  a:visited {
    color: $second-color;
    text-decoration: none;
    @include text($font-nav...);
  }
}

.submenu{
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 30px;
    left: 0;
    background-color: $second-color;
  width: 250px;
opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  a:link{
    color: $main-color;
  }
  a:visited{
    color: $main-color;
  }
}

.submenu li a {
    display: block;
    padding: 15px 20px;
    text-decoration: none;
}

.submenu li a:hover {
  color:$second-color;
    background-color: $third-color;
}

.menu > li:hover .submenu {
    display: block;
  opacity: 1;
    visibility: visible;
}

.menu__item{
  position: relative;
}

.menu__item-mobile {
  display: none;
}

.adress {
  display: none;
}

.msg {
  display: none;
}

.burger {
  display: none;
}

.toogle-fix {
  overflow-y: hidden;
}

.toogle-menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-y: hidden;
  z-index: 999;
  align-content: start;
  background: $third-color;
  justify-content: space-between;
  grid-template-columns: 200px 60px;

  .tel {
    position: absolute;
    bottom: 210px;
    margin-left: 10px;
  }

  .tel__number {
    display: block;
  }

  .adress {
    display: flex;
    position: absolute;
    bottom: 170px;
    margin-left: 10px;
    a:link,
    :hover,
    :visited {
      color: $second-color;
      text-decoration: none;
    }
  }

  .adress-icon {
    width: 20px;
    height: 27px;
    fill: white;
  }

  .adress__text {
    padding-top: 2px;
    padding-left: 10px;
  }

  .msg {
    display: flex;
    position: absolute;
    bottom: 80px;
    margin-left: 10px;
    img {
      width: 50px;
      margin-right: 15px;
    }
  }

  .burger {
    &::after {
      animation: ani-burger 0.3s forwards;
      @keyframes ani-burger {
        0% {
          transform: none;
        }
        100% {
          transform: rotate(45deg);
          top: 38px;
        }
      }
    }
    &::before {
      animation: acni-burger 0.3s forwards;
      @keyframes acni-burger {
        0% {
          transform: none;
        }
        100% {
          transform: rotate(-45deg);
          top: 38px;
        }
      }
    }
  }

  .nav__logo {
    padding-bottom: 0;
  }

  .nav__description {
    display: none;
  }

  .logo-img {
    fill: $second-color;
  }

  .tel-icon {
    fill: $second-color;
  }

  .menu {
    display: flex;
    position: absolute;
    flex-direction: column;
    animation: menu 0.6s forwards;
    top: 25%;
    margin-left: 10px;

    a:link {
      @include text($font-nav-sm...);
    }

    a:visited {
      @include text($font-nav-sm...);
    }

    @keyframes menu {
      0% {
        opacity: 0%;
      }
      100% {
        opacity: 100%;
      }
    }
  }

  .menu__item-mobile {
    display: block;
  }

  animation: ani 0.3s forwards;

  @keyframes ani {
    0% {
      background: none;
    }
    100% {
      background: $third-color;
    }
  }
}

.toogle-display {
  display: none;
}

.tel__number {
  a:link {
    color: $second-color;
    @include text($font-h3...);
    text-decoration: none;
  }
}

.header__wrapper {
  position: absolute;
  bottom: 20px;
}

.cta {
  @include button($cta-button...);
  @include text($font-button...);
  display: block;
  border: 0;
  width: 395px;
  height: 89px;
  position: absolute;
  bottom: 5px;
  left: 910px;
  text-decoration: none;
  text-align: center;
  line-height: 89px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cta-default {
  position: unset;
  margin: 0 auto;
}
.discount {
  @include text($font-big...);
  position: absolute;
  left: 200px;
  left: 1339px;
  width: 200px;
  bottom: 10px;
}

.video__container {
  background: $main-color;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -9999;
}
.video__container .bgvideo {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  background-size: cover;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: 100vh;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// .bgvideo {
//   background: $main-color;
//   position: absolute;
//   right: 0;
//   bottom: 0;
// 	width: 100%;
//   height: 100vh;
//   object-fit: cover;
//   z-index: -9999;
// }

// @media quories

@media (max-width: $screen-xxl) {
  @import 'components/button';

  .header {
    margin: 0 auto;
  }

  .tel {
    justify-content: unset;
  }

  .tel__number {
    a:link {
      @include text($font-h3-xl...);
    }
  }

  .adress__text {
    a:link {
      @include text($font-h3-xl...);
    }
  }

  .nav__description {
    @include text($font-small-xl...);
  }

  .cta {
    @include button($cta-button...);
    @include text($font-button-xl...);
    border: 0;
    width: 290px;
    height: 65px;
    position: absolute;
    bottom: 8px;
    line-height: 65px;
    left: 670px;
  }
  .cta-default {
    position: unset;
    margin: 0 auto;
  }

  .discount {
    @include text($font-big-xl...);
    position: absolute;
    left: 200px;
    left: 990px;
    width: 200px;
    bottom: 18px;
  }
  .video__container .bgvideo {
    height: 110vh;
  }
}

@media (max-width: $screen-xl) {
  .submenu{
    opacity: 1;
    visibility: visible;
    position: relative;
    background: none;
    top: 0;
    padding: 5px 0;
    width: 270px;
  }
  
  .nav {
    grid-template-columns: 200px auto 260px 50px;
  }

  .menu {
    display: none;
  }

  .toogle-menu {
    justify-content: space-between;
    grid-template-columns: 160px 60px;
    position: fixed;
    width: 100%;
    overflow-y: hidden;
    .menu {
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 10px;
      top: 19%;
    }
    .menu__item {
      width: 300px;
      margin-right: 60px;
    }
    .submenu__item a:link,
    .submenu__item a:visited {
      background: none;
    font-size: 24px;
    padding: 0;
      line-height: 24px;
       color: $second-color;
      opacity: 0.8;
    }
  }

  .burger {
    display: flex;
    cursor: pointer;
    width: 50px;
    height: 50px;
    justify-content: flex-end;
    &::after {
      content: ' ';
      display: block;
      background-color: $second-color;
      width: 43px;
      height: 2px;
      position: absolute;
      top: 37px;
    }
    &::before {
      content: ' ';
      display: block;
      background-color: $second-color;
      width: 43px;
      height: 2px;
      position: absolute;
      top: 44px;
    }
  }

  .nav__description {
    justify-self: normal;
  }
}

@media (max-width: $screen-lg) {
  .cta {
    position: unset;
    margin-top: 20px;
  }
  .discount {
    left: 300px;
    bottom: 4px;
  }
  .toogle-menu {
    grid-template-columns: 160px 60px;
  }
}

@media (max-width: $screen-md) {
  .header {
    height: 95vh;
    overflow: hidden;
    min-height: 460px;
    position: relative;
  }
  .nav {
    grid-template-columns: 200px auto 60px;
  }
  .toogle-menu {
    grid-template-columns: 200px 60px;
    .menu__item {
      margin-right: 0px;
    }
  }

  .nav__description {
    display: block;
    position: absolute;
    bottom: 355px;
  }

  .tel {
    justify-content: flex-end;
  }
  .tel__number {
    display: none;
  }

  .cta {
    @include text($font-button-sm...);
    width: 100%;
    line-height: 65px;
    margin-bottom: 15px;
    height: 65px;
  }
  .cta-default {
    position: unset;
    margin: 0 auto;
  }

  .discount {
    position: unset;
    margin-left: 50%;
    @include text($font-small-sm...);
    width: 140px;
  }

  .header__wrapper {
    left: 10px;
    right: 10px;
    bottom: 60px;
  }
  .burger {
    &::after {
      top: 31px;
    }
    &::before {
      top: 37px;
    }
  }
}
