.nav__logo {
  text-align: center;
  padding-bottom: 20px;
}
.nav__logo a:link, :visited{
	text-decoration: none;
	color: $second-color;
}
.nav__logo-img {
  width: 162px;
}
.logo-img {
  fill: $third-color;
}
.nav__logo-text {
  @include text($font-logo...);
}

@media (max-width: $screen-xxl) {
  .nav__logo-img {
    width: 130px;
  }
  .nav__logo-text {
    @include text($font-logo-xl...);
  }
}

@media (max-width: $screen-md) {
	.nav__logo{
		padding-bottom: 10px;
		text-align:left;
	}
  .nav__logo-img {
    width: 100px;
  }
  .nav__logo-text {
    @include text($font-logo-sm...);
  }

}
