// abstracts
@import 'abstracts/variables';

// base
@import 'base/typography';
@import 'base/normalize';

// components
@import 'components/button';

html {
  scroll-behavior: smooth;
	-webkit-scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
	-webkit-scroll-behavior: smooth;
  font-family: $font-type-standart;
  font-size: 18px;
  line-height: 23px;
  margin: 0;
	overflow-y: scroll;
}

// layouts

@import 'layouts/header';
@import 'layouts/services';
@import 'layouts/description';
@import 'layouts/price';
@import 'layouts/about';
@import 'layouts/slider';
@import 'layouts/contacts';
@import 'layouts/cta-form';
@import 'layouts/file-form';
@import 'layouts/footer';
@import 'layouts/reviews';
@import 'pages/tnx';

// components

@import 'components/logo';

.tel {
  display: flex;
  align-items: center;
}
.tel-icon {
  width: 30px;
  padding-top: 6px;
  margin-right: 11px;
  fill: $third-color;
}

@media (max-width: $screen-xxl) {
  .tel-icon {
    width: 25px;
  }
}

@media (max-width: $screen-md) {
  .tel-icon {
    width: 20px;
  }
}
