.services {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  padding-top: 110px;
  padding-bottom: 95px;
}

.services-heading {
  width: 100px;
}

.services__wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: center;
	margin-bottom: 60px;
}

.services-list {
  align-self: center;
}

.services-list__item {
  position: relative;
  flex-wrap: wrap;
  align-content: start;
  height: 200px;
  &::before {
    content: ' ';
    position: absolute;
    background: url(../assets/img/arrow-circle.svg) no-repeat;
    width: 70px;
    height: 55px;
    top: 20px;
  }

  list-style: none;
  width: 335px;
  display: flex;
  align-items: flex-end;
  border-top: 2px solid;
  padding-top: 25px;
  &--heading {
    margin-left: 40px;
  }

  &--text {
    margin-left: 40px;
    margin-top: 15px;
  }
}

@media (max-width: $screen-xl) {
  .services-list__item {
    height: 185px;
  }

  .services-list__img {
    width: 300px;
  }
}

@media (max-width: $screen-lg) {
  .services {
    width: 640px;
  }
  .services__wrapper {
    grid-template-columns: 1fr;
    justify-items: start;
  }
  .services-list__img {
    position: absolute;
    left: 360px;
    top: 30%;
    width: 270px;
  }
  .services-list:nth-of-type(1) .services-list__item:first-child {
		margin-top: 20px;
    border-top: none;
  }
}

@media (max-width: $screen-md) {

	.services{
		padding-top: 60px;
		max-width: 300px;
		padding-bottom: 60px;

	}
  .services__wrapper {
    justify-items: center;
  }
  .services-list__img {
    position: unset;
    width: 170px;
		margin-top: 60px;
  }
  .services-list__item {
		width: 300px;
    height: 180px;
  }
	
  .services-list:nth-of-type(2) .services-list__item:first-child {
		margin-top: 60px;
    border-top: none;
  }
}
