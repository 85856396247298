.cta-form {
  background: $main-color;
}
.cta-form__wrapper {
  width: 1250px;
  background: $second-color;
  margin: 0 auto;
}

.cta-form__content {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.cta-form__heading {
  width: 65%;
}

.cta-form__text {
  width: 32%;
}

.cta-form__input {
  width: 350px;
  height: 90px;
  @include text($font-button...);
  border: 2px solid;
  padding: 10px;
  border-color: $main-color;
}

.cta-form__button {
  @include button($cta-button...);
  @include text($font-button...);
  width: 360px;
  height: 90px;
  border: 0;
}

// @media quories

@media (max-width: $screen-xxl) {
  .cta-form__input {
    @include text($font-button-xl...);
    line-height: 65px;
    height: 65px;
  }

  .cta-form__button {
    @include text($font-button-xl...);
    line-height: 65px;
    height: 65px;
  }
}

@media (max-width: $screen-xl) {
  .cta-form__input {
    @include text($font-button-lg...);
    line-height: 65px;
    height: 65px;
  }

  .cta-form__button {
    @include text($font-button-lg...);
    line-height: 65px;
    height: 65px;
  }
}

@media (max-width: $screen-lg) {
  .cta-form__input {
    @include text($font-button-md...);
    line-height: 65px;
    height: 65px;
  }

  .cta-form__button {
    @include text($font-button-md...);
    line-height: 65px;
    height: 65px;
  }
}

@media (max-width: $screen-md) {
  .cta-form {
    max-width: 320px;
    background-color: $second-color;
    margin: 0 auto;
    padding-bottom: 50px;
  }
  .cta-form__wrapper {
    width: 300px;
  }
  .cta-form__content {
		gap: 0;
    padding: 0;
  }
  .cta-form__input {
    @include text($font-button-sm...);
    width: 100%;
		margin-bottom: 15px;
    height: 65px;
  }
  .cta-form__heading {
    margin-top: 30px;
  }
  .cta-form__text {
		margin-top: 15px;
		margin-bottom: 15px;
    @include text($font-big-sm...);
    width: 230px;
  }
  .cta-form__button {
    @include text($font-button-sm...);
		height: 65px;
  }
}
