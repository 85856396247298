.thx__container {
  background: $main-color;
  width: 100%;
  height: 100vh;
  color: $second-color;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}
.thx__text {
  width: 300px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -125px 0 0 -125px;
}

.thx__contacts{
	margin-top: 30px;
}

.thx__heading{
	width: 100%;
	font-size: 25px;
}

.thx__tel{
	font-size: 30px;
	margin-bottom: 60px;
	margin-top: 20px;
	& a:link{
		color: #fff;
		text-decoration: none;
	}
}
