// Color variables

$main-color: #121420;
$second-color: #ffffff;
$third-color: #bc1c1e;

// Screen variables

$screen-xxl: 1600px;
$screen-xl: 1280px;
$screen-lg: 1024px;
$screen-md: 758px;
$screen-sm: 320px;

