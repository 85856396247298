.description {
  background: $main-color;
  color: $second-color;
}

.description__wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: justify;
  padding-bottom: 60px;
}

.description__text {
  @include text($font-small...);
}

@media (max-width: $screen-lg) {
  .description__wrapper {
    max-width: 640px;
  }
  .description__text {
    @include text($font-small-md...);
  }
}
@media (max-width: $screen-md) {
  .description__wrapper {
    max-width: 280px;
  }
  .description__text {
    @include text($font-small-sm...);
  }
}
