.price {
  background: $main-color;
  color: $second-color;
  padding-bottom: 60px;
}

.price__wrapper {
  margin: 0 auto;
  padding-top: 75px;
  max-width: 1200px;
  display: grid;
  grid-template-areas:
    'a b'
    'c b'
    'd b'
    '. b';
  grid-column-gap: 20px;
}

.price-tabs {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  grid-area: c;
  margin-top: 20px;
  margin-bottom: 30px;
}

.active-tab {
  background: $second-color;
  color: $main-color;
}

.price-tabs__item {
  margin-right: 15px;
  padding: 12px 8px;
  border: 1px solid $second-color;
  cursor: pointer;
  margin: 5px 5px 0px 0px;
}

.price__about {
  @include text($font-small...);
  margin-top: 35px;
}

.price-heading {
  grid-area: a;
  height: 70px;
}
.price-button {
  @include button($price-button...);
  @include text($font-button...);
  grid-area: d;
  height: 89px;
  text-align: center;
  line-height: 89px;
  text-decoration: none;
  border: 0;
  &:visited {
    color: $main-color;
  }
}

.price__text {
  grid-area: b;
  display: none;
}

.active-tab-c {
  display: block;
}

.price-text__wrapper {
  border: 1px solid;
  border-color: $second-color;
  padding: 38px;
  margin-bottom: 30px;
}

.price-list {
  border-collapse: collapse;
  border-top: 0px;
  padding: 20px;
}

.price-list__header {
  @include text($font-h3...);
  text-align: left;
  margin-bottom: 20px;
  width: 480px;
}

th,
td {
  text-align: left;
  padding: 20px 14px 20px 0px;
  width: 160px;
}

.price-list__row {
  border-bottom: 1px solid;
}

// @media quories

@media (max-width: $screen-xxl) {
  .price-button {
    @include text($font-button-xl...);
    line-height: 65px;
    height: 65px;
  }
}

@media (max-width: $screen-xl) {
  .price-button {
    @include text($font-button-lg...);
    line-height: 65px;
    height: 65px;
  }
  .price__wrapper {
    max-width: 964px;
  }
}

@media (max-width: $screen-lg) {
  .price-button {
    @include text($font-button-md...);
    line-height: 65px;
    height: 65px;
  }
	.price-heading{
		height: 40px;
	}
  .price-list__header {
    @include text($font-h3-md...);
  }
  .price__wrapper {
    grid-template-areas: 'a' 'c' 'b' 'd';
    max-width: 640px;
  }
}

@media (max-width: $screen-md) {
  .price-list__header {
    @include text($font-h3-sm...);
  }
  th {
    @include text($font-big-sm...);
  }
  td {
    @include text($font-text-sm...);
  }
  .price__wrapper {
    max-width: 300px;
  }
  .price__text {
    width: 300px;
  }
  .price-text__wrapper {
    overflow: auto;
    padding: 25px;
    padding-top: 35px;
  }
  .price-list__header {
    padding-right: 210px;
  }
  .price-list__row:first-child .price-list__cell {
    padding-top: 0;
  }
  .price-list {
    width: 550px;
  }

  .price-button {
    @include text($font-button-sm...);
    line-height: 65px;
    height: 65px;
  }
}
