.contacts {
  background: $main-color;
  color: $second-color;
}

.contacts__map-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  grid-column: 1/3;
}

#map {
  width: 100%px;
  height: 500px;
}

.ymaps-2-1-79-map {
  filter: grayscale(1);
  -ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  mix-blend-mode: difference;
  width: 100%;
  height: 100%;
}

.shape-map {
  mix-blend-mode: difference;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ffffff;
  top: 0;
}

.contacts__wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.contacts__heading {
  @include text($font-h2...);
  color: $second-color;
  grid-column: 1/4;
}

.contacts__map {
  grid-column: 1/3;
}

.contacts__tel {
  display: flex;
  @include text($font-h3...);
  color: $second-color;
  text-decoration: none;
  margin-bottom: 5px;
  &-icon {
    .tel-icon {
      padding-top: 0;
    }
  }
}

.contacts__address {
  display: flex;
  &-text {
    @include text($font-h3...);
    a {
      text-decoration: none;
      color: $second-color;
    }
  }
  &-icon {
    width: 45px;
    fill: $third-color;
    margin-right: 20px;
  }
}

.contacts__time {
  width: 212px;
  padding-bottom: 20px;
}

.contacts__text {
  padding-bottom: 20px;
}

.contacts__cta-text {
  @include text($font-h3...);
}
.contacts__msg {
  margin-top: 15px;
  margin-bottom: 15px;
  a:link {
    color: transparent;
  }
  img {
    width: 40px;
    margin-right: 20px;
  }
}
.contacts__form {
  grid-column: 1/4;
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
  margin-top: 30px;
}

.contacts__input {
  height: 89px;
  width: 350px;
  @include text($font-button...);
  border: 2px solid;
  padding: 10px;
  border-color: $second-color;
  background: $main-color;
  color: $second-color;
}

.contacts__button {
  @include button($cta-button...);
  @include text($font-button...);
  width: 360px;
  height: 89px;
}

// @media quories

@media (max-width: $screen-xxl) {
  .contacts__input {
    height: 65px;
    line-height: 65px;
    @include text($font-button-xl...);
  }
  .contacts__button {
    line-height: 65px;
    @include button($cta-button...);
    @include text($font-button-xl...);
    height: 65px;
  }
}

@media (max-width: $screen-xl) {
}

@media (max-width: $screen-lg) {
}

@media (max-width: $screen-md) {
  .contacts__wrapper {
    grid-template-columns: 1fr;
    flex-wrap: wrap;
    max-width: 300px;
  }
  .contacts__heading {
    @include text($font-h2-sm...);
    grid-column: 1/2;
  }
  .contacts__map {
    grid-column: 1/2;
    width: 100%;
  }

  .contacts__map-container {
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
  }

  #map {
    width: 300px;
    height: 300px;
  }
  .contacts__form {
    grid-column: 1/2;
    flex-wrap: wrap;
    margin-top: 0;
  }
  .contacts__input {
    width: 100%;
    height: 65px;
    margin-bottom: 15px;
    @include text($font-button-sm...);
  }
  .contacts__button {
    width: 100%;
    @include button($cta-button...);
    @include text($font-button-sm...);
    height: 65px;
  }
  .contacts__tel,
  .contacts__address,
  .contacts__cta-text,
  .contacts__address-text {
    @include text($font-h3-sm...);
    line-height: 20px;
  }
  .contacts__address {
    &-icon {
      width: 30px;
    }
  }
  .contacts__address-text a:link {
    @include text($font-h3-sm...);
    line-height: 20px;
    color: $second-color;
  }
  .contacts__time {
    @include text($font-text-sm...);
  }
  .contacts__text {
    @include text($font-small-sm...);
  }
}
